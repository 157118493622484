export class TeacherModel {
    constructor(teacher) {
        this.id = teacher.id;
        this.teacherId = this.getUserId(teacher.user_id)
        this.userId = teacher.user_id
        this.teacherName = teacher?.user?.name ?? ''
        this.phone = teacher?.user?.phone ?? ''
        this.center = teacher?.user?.profile?.center?.name ?? ''
    }
    getUserId(userId) {
        if (userId && userId !== '') {
            return userId + 100000
        }
    }
}