<template>
  <div style="margin-top: 15px">
    <span>Danh sách giảng viên</span>
    <div class="example-preview table-responsive mt-8">
      <table class="table table-vertical-center table-bordered">
        <thead>
        <tr style="background-color: #F8F8F8;">
          <th scope="col" class="w-[80px]" style="background: #F8F8F8; text-align: start; white-space: nowrap">Mã giảng viên</th>
          <th scope="col" class="min-w-[150px]" style="background: #F8F8F8;white-space: nowrap; text-align: start">Họ tên giảng viên</th>
          <th scope="col" class="min-w-[100px]" style="background: #F8F8F8; text-align: start;  white-space: nowrap">Số điện thoại</th>
          <th scope="col" class="min-w-[200px]" style="background: #F8F8F8; text-align: start;  white-space: nowrap">Trung tâm</th>
          <th scope="col" class="w-[80px]" style="background: #F8F8F8; text-align: center;  white-space: nowrap">Rời nhóm</th>
        </tr>
        </thead>
        <tbody v-if="dataTable?.length > 0 && !loading">
        <tr v-for="item of dataTable" :key="item?.id">
          <td style="text-align: start; text-transform: uppercase">{{ item?.teacherId }}</td>
          <td style="text-align: start; ">
            <span style="padding: 4px 8px; border-radius: 9999px; white-space: nowrap; word-break: break-word">{{ item?.teacherName }}</span>
          </td>
          <td>{{ item?.phone }}</td>
          <td>
            <p class="line-clamp-1" :title="item.center" style="white-space: pre-wrap; text-align: start; margin-bottom: 0">
              {{ item.center }}
            </p>
          </td>
          <td>
            <div style="display: flex; justify-content: center">
              <button class="btn btn-icon btn-xs btn-outline-danger" title="Rời nhóm" @click="handleConfirmDeleteGroup(item)">
                <img src="/icons/out-group.png" style="object-fit: cover" alt=""/>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr style="word-break: break-word; white-space: nowrap; position: relative; height: 40px; border: 1px solid #F8F8F8;">
          <div style="position: absolute; left: 50%; translate: -50%; top: 10px;">
            <div style="line-height: 25px !important;">Chưa có giảng viên nào trong nhóm</div>
          </div>
        </tr>
        </tbody>
      </table>
    </div>
    <el-button class="add-btn" type="info" @click="isAdd = true"><i class="el-icon-plus"></i>Thêm giảng viên</el-button>
    <el-dialog
        v-if="isAdd" width="30%"
        :visible.sync="isAdd"
        append-to-body :show-close="false"
        title="Thêm mới giảng viên vào nhóm"
        :before-close="handleCloseDialog"
    >
      <hr style="margin-top: -20px; margin-bottom: 20px"/>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" id="area_create" @submit.prevent="handleSubmit(handleCreateTeacher)">
          <ValidationProvider vid="Giảng viên" name="Giảng viên" rules="required" v-slot="{ errors,classes }">
            <div style="display: flex; flex-direction: column; align-items: start; gap: 5px; margin-top: 10px">
              <span>Tìm giảng viên <span style="color: red">*</span></span>
              <el-autocomplete
                  style="width: 100%"
                  :clearable="true"
                  v-model="queryString"
                  :fetch-suggestions="querySearch"
                  placeholder="Nhập mã nhân sự hoặc số điện thoại giảng viên"
                  :debounce="300"
                  size="small"
                  @select="handleSelect"
              ></el-autocomplete>
            </div>
            <div class="fv-plugins-message-container">
              <div data-field="Trưởng nhóm" data-validator="notEmpty" class="fv-help-block">{{
                  errors[0]
                }}
              </div>
            </div>
            <div style="display: flex; justify-content: end; gap: 5px; margin-top: 10px">
              <el-button @click="handleCloseDialog">Đóng</el-button>
              <button type="submit" :disabled="disabled" style="color: white; background: #5E00B699; border-radius: 3px; padding: 0 10px">Thêm vào nhóm</button>
            </div>
          </ValidationProvider>
        </form>
      </ValidationObserver>
    </el-dialog>
    <el-dialog
        v-if="isConfirmDelete"
        :visible.sync="isConfirmDelete"
        width="30%"
        append-to-body :show-close="false"
        title="Xóa giảng viên khỏi nhóm"
        :before-close="handleCloseDialogConfirm"
    >
      <hr style="margin-top: -20px; margin-bottom: 20px"/>
      <p>Bạn có chắc chắn muốn xóa giảng viên
        <span style="font-weight: 600; color: red">{{teacherSelected.teacherName}}</span>
        khỏi nhóm <span style="font-weight: 600">{{nameGroup}}</span>?
      </p>
      <div style="display: flex; justify-content: end; gap: 5px; margin-top: 10px">
        <el-button @click="handleCloseDialogConfirm">Đóng</el-button>
        <button type="submit" style="color: white; background: #5E00B699; border-radius: 3px; padding: 0 10px" @click="handleTeacherOutGroup">Xác nhận</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {GET_LIST_CUSTOMER} from "../../../../core/services/store/task-management/task-management.module";
import {ADD_TEACHER_TO_GROUP, TEACHER_OUT_GROUP} from "../../../../core/services/store/course/classes.module";

export default {
  name: 'TableTeacherList',
  data() {
    return {
      isAdd: false,
      queryString: '',
      teacherId: null,
      teacherSelected: null,
      isConfirmDelete: false,
      disabled: false,
      notFoundLecture: true
    }
  },
  props: {
    idGroup: {
      type: Number,
      default: null,
      required: true
    },
    dataTable: {
      type: Array,
      default: [],
      required: true
    },
    nameGroup: {
      type: String,
      default: '',
      required: true
    },
    loading: {
      type: Boolean,
      default: true,
      required: true
    }
  },
  methods: {
    handleCloseDialog() {
      this.isAdd = false
      this.queryString = ''
    },
    handleCloseDialogConfirm() {
      this.isConfirmDelete = false
    },
    querySearch(queryString, callback) {
      if (!queryString) {
        return callback([]);
      }
      const payload = {
        keyword: this.queryString,
      }
      this.$store.dispatch(GET_LIST_CUSTOMER, payload).then(res => {
        if (!res.error && res.data && res.data.length > 0) {
          callback(res?.data.map(item => ({id: item.id, value: item.name,})))
          this.notFoundLecture = false;
        }
        if(res.data.length === 0){
          this.notFoundLecture = true;
        }
      }).catch(() => {
        callback([]);
      })
    },
    handleSelect(item) {
      this.teacherId = +item?.id
    },
    handleCreateTeacher() {
      if (this.notFoundLecture){
        this.$message.error('Không tìm thấy giảng viên')
        return
      }
      this.disabled = true
      const payload = {
        user_id: this.teacherId,
        group_id: this.idGroup
      }
      this.$store.dispatch(ADD_TEACHER_TO_GROUP, payload).then(res => {
        if (!res.error) {
          this.isAdd = false
          this.$emit('getDetailGroupTeacher')
          this.queryString = ''
          this.$message.success('Thêm giảng viên vào nhóm thành công!')
        }
      }).catch((e) => {
        this.$message.error(e.data.message)
      }).finally(() => this.disabled = false)
    },
    handleConfirmDeleteGroup(teacher) {
      this.isConfirmDelete = true
      this.teacherSelected = teacher
    },
    handleTeacherOutGroup() {
      const payload = {
        user_id: this.teacherSelected.userId
      }
      this.$store.dispatch(TEACHER_OUT_GROUP, payload).then(res => {
        if (!res.error) {
          this.$emit('getDetailGroupTeacher')
          this.isConfirmDelete = false
          this.$message.success('Xóa giảng viên khỏi nhóm thành công')
        }
      }).catch(e => {
        this.$message.error(e.data.message)
      })
    }
  }
}
</script>

<style scoped>
.add-btn {
  background: #1BC5BD;
  color: white;
  border: none;
}
.add-btn i {
  color: white;
}
</style>