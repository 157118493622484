<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="area_create" @submit.prevent="handleSubmit(handleCreateGroupTeacher)">
      <ValidationProvider vid="Tên nhóm giảng viên" name="Tên nhóm giảng viên" rules="required|max:30" v-slot="{ errors,classes }">
        <div style="display: flex; flex-direction: column; align-items: start; gap: 5px; margin-top: 10px">
          <span>Tên nhóm giảng viên <span style="color: red">*</span></span>
          <el-input size="small" v-model="nameTeacher" placeholder="Nhập tên nhóm giảng viên"/>
        </div>
        <div class="fv-plugins-message-container">
          <div data-field="Tên nhóm giảng viên" data-validator="notEmpty" class="fv-help-block">{{
              errors[0]
            }}
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="Trưởng nhóm" name="Trưởng nhóm" rules="required" v-slot="{ errors,classes }">
        <div style="display: flex; flex-direction: column; align-items: start; gap: 5px; margin-top: 10px">
          <span>Trưởng nhóm <span style="color: red">*</span></span>
          <el-autocomplete
              style="width: 100%"
              :clearable="true"
              v-model="queryString"
              :fetch-suggestions="querySearch"
              placeholder="Nhập mã nhân sự, số điện thoại của tài khoản"
              :debounce="300"
              size="small"
              @select="handleSelect"
          ></el-autocomplete>
        </div>
        <div class="fv-plugins-message-container">
          <div data-field="Trưởng nhóm" data-validator="notEmpty" class="fv-help-block">{{
              errors[0]
            }}
          </div>
        </div>
      </ValidationProvider>
      <div style="display: flex; flex-direction: column; align-items: start; gap: 5px; margin-top: 10px">
        <span>Hiển thị</span>
        <el-switch
            v-model="isShow"
            active-color="#13ce66"
            inactive-color="#EBEDF3">
        </el-switch>
      </div>
      <div style="display: flex; justify-content: end; gap: 5px; margin-top: 10px">
        <el-button @click="closeDialog">Đóng</el-button>
        <button :disabled="disabled" style="background: #5E00B6; color: white; padding-left: 15px; padding-right: 15px; border-radius: 4px" type="submit">Thêm mới</button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {CREATE_GROUP_TEACHER} from "../../../../core/services/store/course/classes.module";
import {GET_LIST_CUSTOMER} from "../../../../core/services/store/task-management/task-management.module";

export default {
  name: 'DialogAddGroupTeacher',
  data() {
    return {
      queryString: '',
      nameTeacher: '',
      leaderId: null,
      isShow: true,
      disabled: false,
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    handleSelect(item) {
      this.leaderId = +item?.id
    },
    handleCreateGroupTeacher() {
      this.disabled = true
      const payload = {
        name: this.nameTeacher,
        leader_id: this.leaderId,
        status: this.isShow,
      }
      this.$store.dispatch(CREATE_GROUP_TEACHER, payload).then(res => {
        this.closeDialog();
        this.$message.success('Thêm nhóm giảng viên thành công!');
        this.$emit('getListGroupTeacher');
      }).catch(e => {
        this.$message.error(e.data.message)
      }).finally(() => {
        this.disabled = false
      })
    },
    querySearch(queryString, callback) {
      if (!queryString) {
        return callback([]);
      }
      const payload = {
        keyword: this.queryString.trim(),
      }
      this.$store.dispatch(GET_LIST_CUSTOMER, payload).then(res => {
        if (!res.error && res.data && res.data.length > 0) {
          callback(res?.data.map(item => ({id: item.id, value: item.name})))
        }
      }).catch(() => {
        callback([]);
      })
    },
  }
}
</script>

<style scoped>

</style>