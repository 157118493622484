<template>
  <div class="py-4 px-8" style="display: flex; align-items: center; justify-content: space-between">
    <span style="font-size: 13px; font-weight: 500">Quản lý nhóm giảng viên</span>
    <el-button type="text" style="background: #1BC5BD; color: white; padding: 10px 20px" @click="isAdd = true">Thêm mới</el-button>
    <el-dialog
        v-if="isAdd"
        :visible.sync="isAdd"
        title="Thêm mới nhóm giảng viên"
        width="30%" :show-close="false"
        :before-close="handleClose"
    >
      <hr style="margin-top: -20px; margin-bottom: 20px"/>
      <DialogAddGroupTeacher @closeDialog="isAdd = false" @getListGroupTeacher="getListGroupTeacher"/>
    </el-dialog>
  </div>
</template>

<script>
import DialogAddGroupTeacher from "./DialogAddGroupTeacher.vue";

export default {
  name: "HeaderManageGroupTeacher",
  components: {DialogAddGroupTeacher},
  data() {
    return {
      isAdd: false
    }
  },
  methods: {
    handleClose() {
      this.isAdd = false
    },
    getListGroupTeacher() {
      this.$emit('getListGroupTeacher')
    }
  }
}
</script>

<style scoped>

</style>