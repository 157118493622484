<template>
  <div v-loading="loading">
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form class="form" id="area_create" @submit.prevent="handleSubmit(handleUpdateGroupTeacher)">
        <ValidationProvider vid="Tên nhóm giảng viên" name="Tên nhóm giảng viên" rules="required" v-slot="{ errors,classes }">
          <div style="display: flex; flex-direction: column; align-items: start; gap: 5px; margin-top: 10px">
            <span>Tên nhóm giảng viên <span style="color: red">*</span></span>
            <el-input size="small" v-model="nameTeacher" :disabled="disableForm" placeholder="Nhập tên nhóm giảng viên"/>
          </div>
          <div class="fv-plugins-message-container">
            <div data-field="Tên nhóm giảng viên" data-validator="notEmpty" class="fv-help-block">{{
                errors[0]
              }}
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider vid="Trưởng nhóm" name="Trưởng nhóm" rules="required" v-slot="{ errors,classes }">
          <div style="display: flex; flex-direction: column; align-items: start; gap: 5px; margin-top: 10px">
            <span>Trưởng nhóm <span style="color: red">*</span></span>
            <el-input v-if="disableForm" size="small" :value="leaderName" :disabled="disableForm" placeholder="Nhập mã nhân sự, số điện thoại của tài khoản"/>
            <el-autocomplete
                v-else
                style="width: 100%"
                :clearable="true"
                v-model="queryString"
                :fetch-suggestions="querySearch"
                placeholder="Nhập mã nhân sự, số điện thoại của tài khoản"
                :debounce="300"
                size="small"
                @select="handleSelect"
            ></el-autocomplete>
          </div>
          <div class="fv-plugins-message-container">
            <div data-field="Trưởng nhóm" data-validator="notEmpty" class="fv-help-block">{{
                errors[0]
              }}
            </div>
          </div>
        </ValidationProvider>
        <div style="display: flex; justify-content: end; gap: 5px; margin-top: 10px">
          <el-button class="edit-btn__form" v-if="disableForm" type="text" @click="confirmEditTeacherDetail"><i class="el-icon-edit"></i> Sửa thông tin</el-button>
          <div v-else style="display: flex; align-items: center; gap: 10px; margin-top: 12px">
            <el-button style="background: #E6E6E6; height: 30px; display: flex; align-items: center; border-radius: 4px" @click="disableForm = true">Hủy</el-button>
            <button style="background: #1BC5BD; color: white; height: 30px; padding: 0 15px; border-radius: 4px" :disabled="disableSubmitForm" type="submit">Lưu</button>
          </div>
        </div>
        <div style="display: flex; flex-direction: column; align-items: start; gap: 5px; margin-top: 10px">
          <span>Hiển thị</span>
          <el-switch
              v-model="isShow"
              active-color="#13ce66"
              @change="updateStatusTeacher()"
              inactive-color="#EBEDF3">
          </el-switch>
        </div>
      </form>
    </ValidationObserver>
    <TableTeacherList
        :id-group="idGroup"
        :data-table="teacherList"
        :name-group='nameTeacher'
        :loading="loading"
        @getDetailGroupTeacher="getDetail"
    />
  </div>
</template>

<script>
import {GET_LIST_CUSTOMER} from "../../../../core/services/store/task-management/task-management.module";
import {
  GET_DETAIL_TEACHER,
  UPDATE_GROUP_TEACHER,
  UPDATE_STATUS_TEACHER
} from "../../../../core/services/store/course/classes.module";
import TableTeacherList from "./TableTeacherList.vue";
import {TeacherModel} from "../model/teacherModel";

export default {
  name: 'DialogDetailTeacherGroup',
  components: {TableTeacherList},
  props: {
    idGroup : {
      type: String | Number | null,
      required: true,
      default: null,
    }
  },
  data() {
    return {
      queryString: '',
      nameTeacher: '',
      leaderId: null,
      leaderName: null,
      isShow: false,
      disableForm: true,
      idDetail: null,
      loading: true,
      teacherList: [],
      disableSubmitForm: false
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    getDetail() {
      this.$store.dispatch(GET_DETAIL_TEACHER, this.idGroup).then(res => {
        if (!res.error) {
          this.nameTeacher = res.data?.name
          this.leaderId = res.data?.leader_id
          this.isShow = res.data?.status === 1
          this.leaderName = res.data?.leader_group?.name
          this.idDetail = res.data.id
          this.teacherList = res.data?.members?.map(teacher => {
            return new TeacherModel(teacher)
          })
        }
      }).catch(() => {
        this.$message.error('Tải dữ liệu thất bại')
      }).finally(() => this.loading = false)
    },
    confirmEditTeacherDetail() {
      this.disableForm = false
      this.queryString = this.leaderName
    },
    async handleUpdateGroupTeacher() {
      this.disableSubmitForm = true
      const payload = {
        id: this.idDetail,
        obj: {
          name: this.nameTeacher,
          leader_id: this.leaderId
        }
      }
      this.$store.dispatch(UPDATE_GROUP_TEACHER, payload).then(res => {
        if (!res.error) {
          this.disableForm = true
          this.$message.success('Cập nhật nhóm giảng viên thành công')
          this.getDetail()
          this.$emit('getListGroupTeacher');
        }
      }).catch(e => {
        this.$message.success('Cập nhật nhóm giảng viên không thành công')
      }).finally(() => this.disableSubmitForm = false)
    },
    querySearch(queryString, callback) {
      if (!queryString) {
        return callback([]);
      }
      const payload = {
        keyword: this.queryString,
      }
      this.$store.dispatch(GET_LIST_CUSTOMER, payload).then(res => {
        if (!res.error) {
          callback(res?.data.map(item => ({ id: item.id, value: item.name })))
        }
      }).catch(() => {
        callback([]);
      })
    },
    handleSelect(item) {
      this.leaderId = +item?.id
    },
    updateStatusTeacher() {
      const payload = {
        id: this.idDetail,
        obj: {
          status: this.isShow,
        }
      }
      this.$store.dispatch(UPDATE_STATUS_TEACHER, payload).then(res => {
        if (!res.error) {
          this.$emit('getListGroupTeacher');
          this.$message.success('Cập nhật trạng thái nhóm giảng viên thành công')
        }
      }).catch((e) => {
        this.$message.error('Cập nhật trạng thái nhóm giảng viên không thành công')
      })
    }
  }
}
</script>

<style scoped>
.edit-btn__form {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px 20px;
  color: white;
  background: #4e4ec1;
}
</style>