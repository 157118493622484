<template>
  <div style="background: white; border-radius: 5px" v-loading="loading">
    <HeaderManageGroupTeacher @getListGroupTeacher="getListManageGroupTeacher"/>
    <hr style="margin-top: -1px;"/>
    <div class="py-4 px-8">
      <div class="grid-filter">
        <div class="display-flex-column">
          <span style="font-weight: 600">Nhóm giảng viên</span>
          <el-input
              clearable
              placeholder="Nhập tên nhóm giảng viên"
              suffix-icon="el-icon-search"
              v-model="query.keyword"
              @change="handleQueryFilter"
          >
          </el-input>
        </div>
        <div class="display-flex-column">
          <span style="font-weight: 600">Trạng thái</span>
          <el-select v-model="query.status" filterable clearable placeholder="Chọn trạng thái" @change="handleQueryFilter">
            <el-option
                v-for="item in statusOption"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="example-preview table-responsive mt-8">
        <table class="table table-vertical-center table-bordered">
          <thead>
          <tr style="background-color: #F8F8F8;">
            <th scope="col" style="background: #F8F8F8; min-width: 100px; text-align: start">ID nhóm</th>
            <th scope="col" style="background: #F8F8F8; text-align: start; min-width: 220px;">Tên nhóm</th>
            <th scope="col" style="background: #F8F8F8; text-align: start; min-width: 180px">Trưởng nhóm</th>
            <th scope="col" style="background: #F8F8F8; text-align: start; min-width: 140px">Trạng thái</th>
            <th scope="col" style="background: #F8F8F8; text-align: start; min-width: 200px">Thời gian cập nhật</th>
            <th scope="col" style="background: #F8F8F8; text-align: start; min-width: 200px">Người cập nhật</th>
            <th scope="col" style="background: #F8F8F8; text-align: center; min-width: 120px">Hành động</th>
          </tr>
          </thead>
          <tbody v-if="dataTable?.length > 0">
            <tr v-for="item of dataTable" :key="item?.id" style="border: 1px solid #EBEDF3; border-top: 0">
              <td style="text-align: start">{{ item?.id }}</td>
              <td style="text-align: start" :title="item?.name">
                <span class="line-clamp-1">{{ item?.name }}</span>
              </td>
              <td style="text-align: start">
                <span :title="item?.captain">{{ item?.captain }}</span>
              </td>
              <td style="text-align: start">
                <span :class="item.status === 1 ? 'show' : 'hidden'" style="padding: 4px 8px; border-radius: 5px; width: 70px; display: flex; justify-content: center">
                  {{ item?.labelStatus }}
                </span>
              </td>
              <td style="text-align: start">
                <span class="line-clamp-1">{{ item?.updatedAt }}</span>
              </td>
              <td style="text-align: start">
                <span :title="item.personUpdate">{{ item?.personUpdate }}</span>
              </td>
              <td style="text-align: center">
                <button class="btn btn-icon btn-xs btn-outline-info" title="Xem" @click="viewDetail(item.id)">
                  <i class="fas far fa-eye"></i>
                </button>
                <button class="btn btn-icon btn-xs btn-outline-danger ml-2" title="Xoá" @click="handleConfirmDeleteGroup(item)">
                  <i class="el-icon-delete"></i>
                </button>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && dataTable?.length === 0">
          <tr style="word-break: break-word; white-space: nowrap; position: relative; height: 40px">
            <div style="position: absolute; left: 50%; translate: -50%; top: 10px;">
              <div style="line-height: 25px !important;">Chưa có dữ liệu</div>
            </div>
          </tr>
          </tbody>
        </table>
        <div v-if="dataTable?.length > 0">
          <div class="edu-paginate mx-auto text-center mt-10 mb-5"
               style="display: flex; justify-content: center; align-items: center; height: 50px">
            <paginate
                v-model="page"
                :page-count="lastPage"
                :page-range="3"
                :margin-pages="1"
                :click-handler="clickCallback"
                :prev-text="'Trước'"
                :next-text="'Sau'"
                :container-class="'pagination b-pagination'"
                :pageLinkClass="'page-link'"
                :next-link-class="'next-link-item'"
                :prev-link-class="'prev-link-item'"
                :prev-class="'page-link'"
                :next-class="'page-link'"
                :page-class="'page-item'"
            >
            </paginate>
          </div>
          <div class="w-100" style="display: flex; justify-content: end; margin-top: -50px">
            Tổng số bản ghi: <span style="color: #0066FF; font-weight: 600; margin-left: 5px;">{{ this.total }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        v-if="isDelete"
        :visible.sync="isDelete"
        title="Xóa nhóm giảng viên"
        width="30%" :show-close="false"
        :before-close="handleCloseDialogDelete"
    >
      <hr style="margin-top: -20px; margin-bottom: 20px"/>
      <span>Bạn có chắc chắn muốn xóa nhóm giảng viên <span style="font-weight: 600; color: red">{{nameGroup}}</span>?</span>
      <div style="display: flex; justify-content: end; gap: 5px; margin-top: 10px">
        <el-button @click="handleCloseDialogDelete">Đóng</el-button>
        <el-button @click="handleDeleteGroup" style="color: white; background: #5E00B6" type="primary">Xóa</el-button>
      </div>
    </el-dialog>
    <el-dialog
        v-if="isDetail"
        :visible.sync="isDetail"
        title="Chi tiết nhóm giảng viên"
        width="40%" :show-close="false"
        :before-close="handleCloseDialogDetail"
    >
      <hr style="margin-top: -20px; margin-bottom: 20px"/>
      <DialogDetailTeacherGroup :id-group="idGroupTeacher" @getListGroupTeacher="getListManageGroupTeacher"/>
      <div style="display: flex; justify-content: end; gap: 5px; margin-top: 10px">
        <el-button @click="handleCloseDialogDetail">Đóng</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HeaderManageGroupTeacher from "./component/HeaderManageGroupTeacher.vue";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {
  DELETE_GROUP_TEACHER,
  GET_LIST_MANAGE_GROUP_TEACHER
} from "../../../core/services/store/course/classes.module";
import {ManageTeacherModel} from "./model/manageGroupTeacherModel";
import DialogDetailTeacherGroup from "./component/DialogDetailTeacherGroup.vue";

export default {
  name: 'ManageGroupTeacher',
  components: {DialogDetailTeacherGroup, HeaderManageGroupTeacher},
  data() {
    return {
      loading: true,
      page: 1,
      lastPage: 1,
      total: 0,
      nameGroup: '',
      isDetail: false,
      idGroupTeacher: null,
      statusOption: [
        {
          label: 'Hiển thị',
          value: 1,
        },
        {
          label: 'Ẩn',
          value: 0,
        },
      ],
      query: {
        keyword: null,
        status: null,
      },
      dataTable: [],
      isDelete: false,
      idGroup: null,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý đơn vị sản xuất"},
      {title: "Quản lý nhóm giảng viên", name: 'ManageGroupTeacher'},
    ]);
    this.getListManageGroupTeacher()
  },
  methods: {
    clickCallback(obj) {
      this.page = obj;
      this.getListManageGroupTeacher()
    },
    handleQueryFilter() {
      this.page = 1
      this.callApi();
      this.pushParams()
    },
    handleCloseDialogDelete() {
      this.isDelete = false
    },
    handleCloseDialogDetail() {
      this.isDetail = false
    },
    getListManageGroupTeacher() {
      this.loading = true
      this.pullParams()
      this.callApi()
    },
    handleConfirmDeleteGroup(item) {
      this.isDelete = true
      this.nameGroup = item.name ?? ''
      this.idGroup = item.id
    },
    handleDeleteGroup() {
      this.$store.dispatch(DELETE_GROUP_TEACHER, this.idGroup).then(res => {
        if (!res.error) {
          this.$message.success('Xóa nhóm giảng viên thành công!');
          this.getListManageGroupTeacher()
        }
      }).catch(e => {
        this.$message.error(e.data.message)
      }).finally(() => this.isDelete = false)
    },
    viewDetail(id) {
      this.isDetail = true
      this.idGroupTeacher = id
    },
    callApi() {
      const payload = {
        page: this.page,
        keyword: this.query.keyword,
        status: this.query.status,
      }
      this.$store.dispatch(GET_LIST_MANAGE_GROUP_TEACHER, payload).then(res => {
        this.loading = false;
        const data = res.data.data
        if (!res.error) {
          this.dataTable = data.map(item => {
            return new ManageTeacherModel(item)
          })
          this.total = res.data?.total
          this.lastPage = res.data?.last_page
          this.page = res.data?.current_page
        }
      }).catch(e => {
        console.log(e, 'Get list manage group teacher error !')
      }).finally(() => {
        this.loading = false
      })
    },
    async pushParams() {
      await this.$router.push({
        name: 'ManageGroupTeacher',
        query : {
          keyword: this.query.keyword,
          status: this.query.status,
        }
      })
    },
    pullParams() {
      this.keyword = this.$route.query.keyword || '';
      if ('status' in this.$route.query && this.$route.query.status !== '') {
        const parsedStatus = parseInt(this.$route.query.status);
        this.query.status = isNaN(parsedStatus) ? null : parsedStatus;
      } else {
        this.query.status = null;
      }
    }
  },
}
</script>

<style scoped>
  .grid-filter {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .display-flex-column {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .show {
    background: #1BC5BD;
    color: white;
  }
  .hidden {
    background: #D8D8D8
  }
</style>