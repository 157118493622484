import moment from "moment-timezone";

export class ManageTeacherModel {
    constructor(obj) {
        this.id = obj.id;
        this.name = obj.name;
        this.captain = obj?.leader_group?.name;
        this.status = obj.status;
        this.labelStatus = obj.status || obj.status === 0 ? this.getStatus(obj?.status) : null
        this.updatedAt = obj.updated_at && this.formatDateTime(obj.updated_at)
        this.personUpdate = obj?.update_user ? `${obj.id + 100000} - ${obj.name}` : ''
    }

    getStatus(status) {
        return status === 1 ? 'Hiển thị' : 'Ẩn'
    }
    formatDateTime(date) {
        if (date) {
            return moment(date).format('HH:mm:ss DD-MM-YYYY');
        } else {
            return ''
        }
    }
}